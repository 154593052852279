import { AboutMeComponent, ContactComponent, PortofolioComponent, ResumeComponent } from './components';
import { PageNotFoundComponent } from './shared/components';

export const ROUTES = [
    { path: '', redirectTo: '/about-me', pathMatch: 'full' },
    { path: 'about-me', component: AboutMeComponent, pathMatch: 'full' },
    { path: 'contact', component: ContactComponent, pathMatch: 'full' },
    { path: 'resume', component: ResumeComponent, pathMatch: 'full' },
    { path: 'portofolio', component: PortofolioComponent, pathMatch: 'full' },
    { path: '**', component: PageNotFoundComponent }
];
